<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title text-center w-100" id="modal-basic-title">Log In</h4>
    <h6 class="w-100 text-center mt-4">
      {{ "loginForm.dontHaveAccount" | cxTranslate }}
      <a href="javascript:void(0)" class="btn-link" (click)="openSignUpModal()"
        >{{ "sloanloginForm.signUp" | cxTranslate }}
      </a>
    </h6>
  </div>
  <cx-global-message></cx-global-message>
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
      <div class="mb-3 form-group">
        <label for="email" class="form-label">{{
          "sloanloginForm.emailAddress.label" | cxTranslate
        }}</label>
        <input
          type="email"
          class="form-control"
          id="email"
          formControlName="userId"
        />
      </div>
      <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
      <div class="mb-3 form-group">
        <label for="password" class="form-label">{{
          "sloanloginForm.password.label" | cxTranslate
        }}</label>
        <input
          class="form-control"
          id="password"
          type="password"
          formControlName="password"
        />
        <cx-form-errors [control]="form.get('password')"></cx-form-errors>
      </div>
      <h6 class="col-12 mt-2 nopad">
        <a href="javascript:void(0)" (click)="openForgotPwd()">{{
          "sloanloginForm.forgotPassword" | cxTranslate
        }}</a>
      </h6>
      <div class="col-12 mb-4 mt-4 nopad">
        <div class="form-check form-check-inline mb-2">
          <!-- <input class="form-check-input mb-3" type="checkbox" name="passwordOptions" id="oneLowerChar"
                value="One lowercase character" [checked]="false" /> -->
          <p class="mt-4">
            {{ "sloanloginForm.policyMsg1" | cxTranslate }}
            <a href='{{this.sloanprivacypolicy}}' target="_blank">{{
              "sloanloginForm.policyMsg2" | cxTranslate
            }}</a>
            {{ "sloanloginForm.policyMsg3" | cxTranslate }}
            <a href='{{this.sloantermsofuse}}' target="_blank">{{
              "sloanloginForm.policyMsg4" | cxTranslate
            }}</a>
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default float-left"
          (click)="closeModal()"
        >
          {{ "sloanloginForm.cancelBtn" | cxTranslate }}
        </button>
        <button
          type="submit"
          [disabled]="form.invalid || disableSubmitBtn"
          class="btn btn-primary float-right"
        >
          {{ "sloanloginForm.logInBtn" | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</div>
