import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthService,
  Country,
  GlobalMessageService,
  GlobalMessageType,
  LoggerService,
  OccSiteAdapter,
  Region,
  normalizeHttpError,
} from '@spartacus/core';
import { Observable, from } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { SloanCpqUserService } from 'src/app/common/services/sloan-cpq-user.service';
import { SLOAN_CPQ_USER_KEY } from 'src/app/constants/storage.constant';
import { Signup } from 'src/app/models/signup.model';
import { SloanLoginComponent } from '../sloan-login/sloan-login.component';
import { environment } from 'src/environments/environment';
declare function ClickwrapSubmitData(firstName: string,lastName: string,companyName: string,email: string,GroupKey);
declare function clickwrapData(SiteAccessId,GroupKey);
@Component({
  selector: 'app-sloan-signup',
  templateUrl: './sloan-signup.component.html',
  styleUrls: ['./sloan-signup.component.scss'],
  providers: [OccSiteAdapter],
})
export class SloanSignupComponent implements OnInit {
  protected logger = inject(LoggerService);
  loginModalRef;
  signUpForm: UntypedFormGroup;
  title = '';
  userTitles: any = [];
  loading: boolean;
  submitted: boolean;
  isUserDetails = true;
  selected: any;
  userRoles: any = [];
  countries: {};
  regions: any = [];
  userCountry: any;
  states: any;
  isocode: string;
  countyIsoCode: string;
  selectedCountry: any;
  country: any;
  loggedIn: false;
  firstName: string;
  lastName: string;
  countries$: Observable<Country[]>;
  isCountryLoading = false;
  regions$: Observable<Region[]>;
  isRegionLoading = false;
  isLowercase = false;
  isUpperCase = false;
  isNumber = false;
  eightCharacters = false;
  companyForm: UntypedFormGroup;
  signupReqObject: Signup;
  disableSubmitBtn = false;
  sloantermsofuse = environment.sloantermsofuse;
  sloanprivacypolicy = environment.sloanprivacypolicy;
  SiteAccessId=environment.siteAccessId;
  GroupKey=environment.groupKey;
  Emaillowercase:string;
  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public occSiteAdapter: OccSiteAdapter,
    protected auth: AuthService,
    protected globalMessageService: GlobalMessageService,
    private userService: SloanCpqUserService,
    private ngbActiveModal: NgbActiveModal,
    private loggerService: LoggerService
  ) {
    clickwrapData(this.SiteAccessId,this.GroupKey);
  }

  initCompanyForm() {
    let pattern = '[0-9]{5}-[0-9]{4}|[0-9]{5}';
    this.companyForm = this.fb.group({
      titleCode: [null, Validators.required],
      roleId: [null, Validators.required],
      companyName: ['', Validators.required],
      phone: ['', Validators.required],
      city: ['', Validators.required],
      state: [null, Validators.required],
      streetAddress: ['', Validators.required],
      countryIso: ['US', Validators.required],
      zipcode: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(pattern)])),
    });
    this.countrySelected({ isocode: 'US' });
  }
  ngOnInit(): void {
    this.signUpForm = this.fb.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, , Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
        acceptanceCheckbox: [false],
        token: ['', Validators.required],
      },
      { validators: this.checkPasswords }
    );

    this.initCompanyForm();
    this.userService.getTitles().subscribe((data) => {
      this.userTitles = data;
      // console.log(this.userTitles);
    });

    this.userService.getRoles().subscribe((data) => {
      this.userRoles = data;
      // console.log(this.userRoles);
    });

    this.getCountries();
  }

  navigateToCompany() {}

  checkPasswordValidations() {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCaseLetters = /[]/g;
    const password = this.signUpForm.value.password;
    this.isLowercase = password.match(lowerCaseLetters);
    this.isUpperCase = password.match(upperCaseLetters);
    this.isNumber = password.match(numbers);
    this.eightCharacters = password && password.length >= 8 ? true : false;
  }

  getCountries() {
    this.isCountryLoading = true;
    this.countries$ = this.occSiteAdapter.loadCountries().pipe(tap(() => (this.isCountryLoading = false)));
  }
  countrySelected(country) {
    this.selectedCountry = country.isocode;
    this.companyForm.get('countryIso').valueChanges.subscribe((selectedCountry) => {
      if (selectedCountry === 'IN') {
        let pattern = '[0-9]{6}';
        this.companyForm.controls['zipcode'].setValidators(
          Validators.compose([Validators.required, Validators.pattern(pattern)])
        );
      }
      if (selectedCountry === 'CA') {
        let pattern = '[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]';
        this.companyForm.controls['zipcode'].setValidators(
          Validators.compose([Validators.required, Validators.pattern(pattern)])
        );
      }
      if (selectedCountry === 'US') {
        let pattern = '[0-9]{5}-[0-9]{4}';
        this.companyForm.controls['zipcode'].setValidators(
          Validators.compose([Validators.required, Validators.pattern(pattern)])
        );
      }
    });

    if (country && country.isocode) {
      this.isRegionLoading = true;
      this.companyForm.controls['state'].setValue(null);
      this.regions$ = this.occSiteAdapter.loadRegions(country.isocode).pipe(tap(() => (this.isRegionLoading = false)));
    } else {
      this.regions$ = null;
    }
  }

  // tslint:disable-next-line: typedef
  openLoginModal() {
    // this.modalRef.hide();
    this.closeModal();
    this.loginModalRef = this.modalService.open(SloanLoginComponent);
  }

  isValidPassword() {
    return this.isLowercase && this.isNumber && this.isUpperCase && this.eightCharacters;
  }

  checkPasswords(group: UntypedFormGroup) {
    // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;

    return password === confirmPassword ? null : { notSame: true };
  }

  // tslint:disable-next-line: typedef
  next() {
    if (this.signUpForm.valid && this.isValidPassword()) {
      this.isUserDetails = !this.isUserDetails;
      this.firstName = this.signUpForm.value.firstName;
      this.lastName = this.signUpForm.value.lastName;
    } else {
      this.signUpForm.markAllAsTouched();
    }
  }

  submitForm() {}

  // tslint:disable-next-line: typedef
  onSubmit() {
    if (this.companyForm.valid) {
      this.signupReqObject = {
        ...this.companyForm.value,
        ...this.signUpForm.value,
      };
      //  console.log(this.signupReqObject);
      this.disableSubmitBtn = true;
      this.userService.register(this.signupReqObject).subscribe(
        (response: any) => {
          this.disableSubmitBtn = false;
          // if (response && response.type === 'userWsDTO'){
          //   this.globalMessageService.add({ key: 'user.domainsBlock' },
          //   GlobalMessageType.MSG_TYPE_ERROR);
          // } else {
          this.Emaillowercase=this.signupReqObject.email.toLowerCase();
          ClickwrapSubmitData(this.signupReqObject.firstName, this.signupReqObject.lastName, this.signupReqObject.companyName, this.Emaillowercase,this.GroupKey);
          this.loginUser();
          // }
        },
        (error) => {
          this.disableSubmitBtn = false;
          const errordetail = normalizeHttpError(error, this.logger);
          if (errordetail?.details) {
            if (errordetail?.details?.length > 0 && errordetail?.details[0]?.type != 'DuplicateUidError') {
              this.globalMessageService.add(
                { raw: errordetail?.details[0]?.message },
                GlobalMessageType.MSG_TYPE_ERROR
              );
            }
          } else {
            this.globalMessageService.add({ key: 'user.signuperrormsg' }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        }
      );
    } else {
      this.companyForm.markAllAsTouched();
    }
  }

  protected loginUser(): void {
    this.disableSubmitBtn = true;
    from(
      this.auth.loginWithCredentials(
        SLOAN_CPQ_USER_KEY + this.signupReqObject.email.toLowerCase(), // backend accepts lowercase emails only
        this.signupReqObject.password
      )
    )
      .pipe(
        withLatestFrom(this.auth.isUserLoggedIn()),
        tap(([_, isLoggedIn]) => {
          if (isLoggedIn) {
            this.disableSubmitBtn = false;
            window.localStorage.setItem('userId', this.signupReqObject.email.toLowerCase());
            // We want to remove error messages on successful login (primary the bad username/password combination)
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.add(
              { key: 'sloanloginForm.loginsucessmsg' },
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            this.closeModal();
            // message logged in successfully
          } else {
            this.disableSubmitBtn = false;
          }
        })
      )
      .subscribe();
  }

  bacKOrCloseModal() {
    if (!this.isUserDetails) {
      this.isUserDetails = !this.isUserDetails;
    } else {
      this.closeModal();
    }
  }
  // tslint:disable-next-line: typedef
  closeModal() {
    this.ngbActiveModal.dismiss();
  }
}
