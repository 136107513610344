import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, GlobalMessageService, GlobalMessageType, RoutingService, WindowRef } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { SloanCpqUserService } from 'src/app/common/services/sloan-cpq-user.service';
import { SLOAN_CPQ_USER_KEY, USER_DATA_KEY } from 'src/app/constants/storage.constant';
import { environment } from 'src/environments/environment';
declare function ClickwrapSubmitData(firstName: string,lastName: string,companyName: string,email: string,GroupKey);
declare function clickwrapData(SiteAccessId,GroupKey);
@Component({
  selector: 'app-sloan-policy-reacceptance',
  templateUrl: './sloan-policy-reacceptance.component.html',
  styleUrl: './sloan-policy-reacceptance.component.scss'
})
export class SloanPolicyReacceptanceComponent implements OnInit{
  reacceptanceForm: UntypedFormGroup;
  disableSubmitBtn = false;
  sloantermsofuse = environment.sloantermsofuse;
  sloanprivacypolicy = environment.sloanprivacypolicy;
  SiteAccessId=environment.siteAccessId;
  GroupKey=environment.groupKey;
  firstName:string;
  lastName:string;
  email:string;
  companyName:string;
  termsofuse: boolean = false;
  Privacypolicy: boolean = false;
  constructor(protected fb: UntypedFormBuilder,
    private ngbActiveModal: NgbActiveModal,
    public authService: AuthService,
    private sloanCpqUsersService: SloanCpqUserService,
    protected globalMessageService: GlobalMessageService,
  ){
    clickwrapData(this.SiteAccessId,this.GroupKey);
    this.sloanCpqUsersService.getCPQUserData();
    this.sloanCpqUsersService.loadUserData().subscribe((res) => {
      this.firstName=res?.firstName;
      this.lastName=res?.lastName;
       this.email = res?.uid;
      if (res?.uid.includes(SLOAN_CPQ_USER_KEY)) {
        this.email = res?.uid.split(SLOAN_CPQ_USER_KEY)[1];
      }
      this.companyName=res?.defaultAddress.companyName;
    });
    this.sloanCpqUsersService.checkpactsafedata$.subscribe(res => {
      this.Privacypolicy = res[environment.Privacypolicy];
      this.termsofuse = res[environment.termsofuse];
    });
  }
  ngOnInit(): void {
    this.reacceptanceForm = this.fb.group(
      {
        //acceptanceCheckbox: [false],
      }
    );
  }
  onSubmit() {
    if(this.email){
      ClickwrapSubmitData(this.firstName, this.lastName, this.companyName, this.email,this.GroupKey);
      this.closeModal();
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
      if (this.termsofuse == false && this.Privacypolicy == true) {
        this.globalMessageService.add(
          { key: 'sloanloginForm.termsofusemsg' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      }
      else if (this.termsofuse == true && this.Privacypolicy == false) {
        this.globalMessageService.add(
          { key: 'sloanloginForm.policyacceptmsg' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      } else {
        this.globalMessageService.add(
          { key: 'sloanloginForm.commonmsg' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      }
      this.sloanCpqUsersService.getCPQUserData();
    }
  }
  closeModal() {
    this.ngbActiveModal.dismiss();
  }
}
